/**
 * WSLR // WindowSeller
 * POS Viewer
 * Frontend
 * 
 * @version 1.0.3
 * @author Christian
 * 
 */
import React from 'react'; 

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

import '../wslr/wslr.scss';

import InfoSlide from '../wslr/slides/infoslide'

import axios from 'axios';
import NumberFormat from 'react-number-format';
import qs from 'qs';
import _ from 'lodash';

var QRCode = require('qrcode.react');
const AutoplaySlider = withAutoplay(AwesomeSlider);

export default class WslrSlider extends React.Component {
    state = {
      products: [],
      slider: (<div>Loading</div>),
      brand: qs.parse(window.location.search, { ignoreQueryPrefix: true }).brand,
      slidestyle: 'style-basic-white',
      apiversion: 1,
    }
  
    componentDidMount() {
        this.buildSlider();
        // tbd
        //setInterval(this.buildSlider.bind(this), 3600*1000);
    }

    buildSlider() {
      console.log("BUILD NEW");
      const brand = this.state.brand;
      var appversion = qs.parse(window.location.search, { ignoreQueryPrefix: true }).v;
      var apiversion = this.state.apiversion;
      axios.post(`https://wslr.io/wslr-frontend/api/public.php`, qs.stringify({ 'brand': brand, 'api': apiversion, 'app': appversion }))
        .then(res => {
            console.log('loaded data: ', res.data);
            const products = res.data;
            if(!_.isEqual(this.state.products, products) && 'string' !== typeof products) {
                console.log('change in products');
                this.setState({ products: products });
                var slides = this.state.products.map(product =>
                    <div key={product.pid} className={["wslr-product", this.state.slidestyle].join(' ')}>
                        <div className="wslr-product-name">{product.name}</div>
                        <div className="wslr-product-description">{product.description}</div>
                        <div className="wslr-product-image" style={{backgroundImage: "url(" + product.image + ")", backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
                        <div className="wslr-product-price"><NumberFormat value={product.price} isNumericString={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix={product.priceCurrency.toLowerCase() === 'eur' ? '€' : ''} /></div>
                        <QRCode className="wslr-product-link-qrcode" renderAs="svg" value={product.url} />
                        <div className="wslr-product-footer-background"></div>
                    </div>
                )
                // add Infoslide at first
                slides.unshift(
                    <div key="WslrInfoSlide" className={["wslr-infoslide", this.state.slidestyle].join(' ')}>
                        <InfoSlide />
                    </div>
                );
                const slider = (
                    <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false}
                    interval={10000}
                    bullets={false}
                    organicArrows={false}
                    fillParent={true}
                    buttons={false}
                    >
                    { slides }
                    </AutoplaySlider>
                );
                this.setState({ slider: slider });
            } else {
                console.log('no change in products');
            }
        });
    }
  
    render() {
        return (
            <div className="slider">{ this.state.slider }</div>
        )
    }
  }