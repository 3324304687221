
import React from 'react'; 
import '../wslr.scss';


export default class InfoSlide extends React.Component {
    render() {
        return (
            <div className="infoslide"></div>
        )
    }
  }